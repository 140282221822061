import axios from '@/axios.js'

const state = () => ({
   layanan_dibutuhkans: []
})

const mutations = {
    SET_LAYANANDIBUTUHKANS (state, payload) {
      state.layanan_dibutuhkans = payload
    },
    ADD_LAYANANDIBUTUHKAN (state, item) {
      state.layanan_dibutuhkans.unshift(item)
    },
    UPDATE_LAYANANDIBUTUHKAN (state, layanan_dibutuhkan) {
      const layanan_dibutuhkanIndex = state.layanan_dibutuhkans.findIndex((p) => p.id === layanan_dibutuhkan.id)
      Object.assign(state.layanan_dibutuhkans[layanan_dibutuhkanIndex], layanan_dibutuhkan)
    },
    REMOVE_LAYANANDIBUTUHKAN (state, itemId) {
      const ItemIndex = state.layanan_dibutuhkans.findIndex((p) => p.id === itemId)
      state.layanan_dibutuhkans.splice(ItemIndex, 1)
    },
}

const actions = {
  fetchLayananDibutuhkan ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`/term-list/layanan_dibutuhkan`)
        .then((response) => {
          commit('SET_LAYANANDIBUTUHKANS', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  addLayananDibutuhkan ({ commit }, layanan_dibutuhkan ) {
    return new Promise((resolve, reject) => {
      axios.post('/term-add', layanan_dibutuhkan)
        .then((response) => {
          commit('ADD_LAYANANDIBUTUHKAN', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateLayananDibutuhkan ({ commit }, layanan_dibutuhkan) {
    return new Promise((resolve, reject) => {
      axios.post(`/term-update/${layanan_dibutuhkan.id}`, layanan_dibutuhkan)
        .then((response) => {
            commit('UPDATE_LAYANANDIBUTUHKAN', response.data.data)
            resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateFormLayananDibutuhkan ({ commit }, layanan_dibutuhkan) {
    return new Promise((resolve, reject) => {
      axios.post(`/term-update-form`, layanan_dibutuhkan)
        .then((response) => {
            commit('UPDATE_LAYANANDIBUTUHKAN', response.data.data)
            resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  removeLayananDibutuhkan ({ commit }, layanan_dibutuhkan) {
    return new Promise((resolve, reject) => {
      axios.delete(`/term-delete/${layanan_dibutuhkan.id}`)
        .then((response) => {
            commit('REMOVE_LAYANANDIBUTUHKAN', layanan_dibutuhkan.id)
            resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  }
}

export default {
  isRegistered: false,
  namespaced: true,
  state,
  actions,
  mutations
}